import React, { useCallback } from "react";
import { projectsConstants } from "../../../../_constants/projects.constants";
import { CardSensor } from "./CardSensor/CardSensor";
import { NotCardSensor } from "./NotCardSensor/NotCardSensor";

export const TerminalDeviceGenericAnalogSensorView = (props) => {

  const {
    terminalDevice,
    onAnalogSensorClick
  } = props

  function sortBySensorIndex(first, second) {
    if (first?.sensorIndex < second?.sensorIndex) {
      return -1;
    }
    if (first?.sensorIndex > second?.sensorIndex) {
      return 1;
    }
    return 0;
  }

  const wrapSensorAndMeasruement = useCallback((sensor, measurement) => {
    return {
      sensor,
      ...measurement,
      equation: measurement?.equation
        ? measurement.equation.replace(
            /{sensorValue}/g,
            sensor.lastValue || 0
          )
        : sensor.lastValue || 0,
      equationDailyAverage: measurement?.equation
      ? measurement.equation.replace(
          /{sensorValue}/g,
          sensor.lastDailyAverageValue || 0
        )
      : sensor.lastDailyAverageValue || 0,
    };
  }, [])

  const getAnalogSensorComponents = useCallback(
    (terminalDevice) => {
      if (terminalDevice?.sensors instanceof Array) {
        let cardSensors = terminalDevice.sensors
          .sort(sortBySensorIndex)
          .filter(
            (sensor) =>
              sensor?.active &&
              sensor?.sensorId?.physicalCommunicationType ===
                projectsConstants.global.sensors.phys.analog
          )
          .flatMap((sensor) => {
            let measurements = []
            if(sensor.measurement){
              measurements.push(wrapSensorAndMeasruement(sensor, sensor.measurement))
            }
            if (
              sensor.sensorId.measurements instanceof Array &&
              sensor.sensorId.measurements.length > 0
            ) {
              measurements = measurements.concat(sensor.sensorId.measurements.map((measurement) => {
                return [wrapSensorAndMeasruement(sensor, measurement)]
              }));
            }

            if(sensor.measures instanceof Array && 
              sensor.measures.length > 0
            ){
              measurements = sensor.measures.map((measure) => {
                return {
                    sensor,
                    calibrationRawValue: 1,
                    calibrationUnitValue: 1,
                    equation: `${measure.value || 0}`,
                    equationDailyAverage: `0`,
                    measurementUnit: measure.unit,
                    sensorUnit: measure.unit,
                    offset: 0,
                  }
              })
            }

            return measurements.length > 0 ? measurements : [
              {
                sensor,
                calibrationRawValue: 1,
                calibrationUnitValue: 1,
                equation: `${sensor.lastValue || 0}`,
                equationDailyAverage: `${sensor.lastDailyAverageValue || 0}`,
                measurementUnit: "V",
                sensorUnit: "V",
                offset: 0,
              },
            ];
          })
          .map((joinedSensor, index) => {
            let measureValue = eval(joinedSensor.equation);
            let dialyAverageMeasureValue = eval(joinedSensor.equationDailyAverage);
            return (
              <CardSensor
                key={index}
                physicalCommunicationType={
                  projectsConstants.global.sensors.phys.analog
                }
                id={joinedSensor.sensor.id}
                sensorIndex={joinedSensor.sensor.sensorIndex}
                sensorValue={
                  measureValue < 0
                    ? joinedSensor.offset < 0
                      ? measureValue
                      : 0
                    : measureValue
                }
                sensorUnit={joinedSensor.measurementUnit}
                loading={joinedSensor?.sensor?.isRequesting}
                alarm={0}
                dateTime={
                  joinedSensor.sensor.lastValueAt &&
                  new Date(joinedSensor.sensor.lastValueAt)
                }
                onClick={onAnalogSensorClick}
                allowSwitchToAverage={!isNaN(joinedSensor?.sensor?.lastDailyAverageValue)}
                dialyAverageSensorValue={dialyAverageMeasureValue}
              />
            );
          });

        return cardSensors.length >= 0 ? (
          cardSensors
        ) : (
          <NotCardSensor message={"No hay sensores analógicos activos."} />
        );
      }
      return <>No hay sensores analógicos configurados.</>;
    },
    [onAnalogSensorClick]
  );

  return <>
   <div className="sensorsCardFather">{getAnalogSensorComponents(terminalDevice)}</div>
  </>;
};
