import { authHeader } from "../_helpers";
import axios from "axios";
import { Output } from "../classes/TerminalDevice/Output/Output";
import { OutputType } from "../classes/OutputType/OutputType";
import { Authservice } from "./Authservice";
import { TerminalDeviceCalibration } from "../classes/TerminalDevice/Calibration/TerminalDeviceCalibration";

const TERMINAL_DEVICE_OUTPUT_CALIBRATION_TYPE = "OUTPUT"

export const OutputService = {
  getActivations,
  postTerminalDeviceOutputConfiguration,
  putTerminalDeviceOutputConfiguration,
  getOutputTypes,
  saveOutputType,
  deleteOutputType,
  updateOutputType,
  reorderOutputTypes,
  getTerminalDeviceOutputRegistersByDay
};

function putTerminalDeviceOutputConfiguration(
  terminalId,
  terminalDeviceId,
  data
) {
  const authToken = authHeader();
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken.Authorization,
  };
  const requestOptions = {
    method: "PUT",
    headers,
  };

  return axios
    .put(
      `${process.env.REACT_APP_API_PATH}/terminals/${terminalId}/devices/${terminalDeviceId}/outputs/`,
      data,
      requestOptions
    )
    .then(handleResponse)
    .then((outputs) => {
      return parseOutputs(outputs);
    });
}

function postTerminalDeviceOutputConfiguration(
  terminalId,
  terminalDeviceId,
  output
) {
  const authToken = authHeader();
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken.Authorization,
  };
  const requestOptions = {
    method: "POST",
    headers,
  };
  return axios
    .post(
      `${process.env.REACT_APP_API_PATH}/terminals/${terminalId}/devices/${terminalDeviceId}/outputs/${output.output}`,
      output,
      requestOptions
    )
    .then(handleResponse)
    .then((outputs) => {
      return parseOutputs(outputs);
    });


}

function getActivations() {
  const authToken = authHeader();
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken.Authorization,
  };
  const requestOptions = {
    method: "GET",
    headers,
  };

  return axios
    .get(
      `${process.env.REACT_APP_API_PATH}/outputs/activations/`,
      requestOptions
    )
    .then(handleResponse)
    .then((tmpZones) => {
      return tmpZones;
    });
}

function getOutputTypes(page, size) {
  const authToken = authHeader();

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken.Authorization,
    },
  };

  return axios
    .get(
      //   `${process.env.REACT_APP_API_PATH}/outputs/types?page=${page}&size=${size}` , requestOptions)
      `${process.env.REACT_APP_API_PATH}/outputs/types/`,
      requestOptions
    )
    .then(handleResponse)
    .then((types) => {
      return parseOutputTypes(types.content);
    });
}

function saveOutputType(OutputType) {
  return axios
    .post(
      `${process.env.REACT_APP_API_PATH}/outputs/types/`,
      OutputType,
      Authservice.getRequestOptions("POST")
    )
    .then(Authservice.handleResponse)
    .then((type) => {
      return parseOutputType(type);
    });
}

function deleteOutputType(outputTypeId) {
  return axios
    .delete(
      `${process.env.REACT_APP_API_PATH}/outputs/types/${outputTypeId}`,
      Authservice.getRequestOptions("DELETE")
    )
    .then(Authservice.handleResponse)
    .then((type) => {
      return parseOutputType(type);
    });
}

function updateOutputType(outputType) {
  return axios
    .put(
      `${process.env.REACT_APP_API_PATH}/outputs/types/${outputType?.id}`,
      outputType,
      Authservice.getRequestOptions("PUT")
    )
    .then(Authservice.handleResponse)
    .then((type) => {
      return parseOutputType(type);
    });
}

function getTerminalDeviceOutputRegistersByDay(
  terminalId,
  terminalDeviceId,
  outputId,
  day,
  outputRegisterType){
    const authToken = authHeader();
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken.Authorization,
    },
  };

  return axios
    .get( `${process.env.REACT_APP_API_PATH}/terminals/${terminalId}/devices/${terminalDeviceId}/outputs/${outputId}/registers/day?day=${encodeURIComponent(day)}&registerType=${encodeURIComponent(outputRegisterType || "NORMAL")}`, requestOptions)
    .then(Authservice.handleResponse)
    .then((registers) => registers);
  }

/**
 *
 * @param {*} outputTypes Array de instancias de objetos de clase OutputType
 */
function reorderOutputTypes(outputTypesDto) {
  if (outputTypesDto instanceof Array && outputTypesDto.length > 0) {
    return axios
      .patch(
        `${process.env.REACT_APP_API_PATH}/outputs/types/hierarchyLevel/`,
        outputTypesDto,
        Authservice.getRequestOptions("PTACH")
      )
      .then(Authservice.handleResponse);
  }
}

function parseOutputTypes(outputTypes) {
  if (outputTypes instanceof Array) {
    return outputTypes.map(parseOutputType);
  }
  return outputTypes;
}

function parseOutputType(outputType) {
  if (outputType) {
    return OutputType.parseFromObjectDto(outputType);
  }
  return outputType;
}

function parseCalibration(calibration) {
  if (calibration) {
    return TerminalDeviceCalibration.parseFromObjectDto(calibration);
  }
  return calibration;
}

function handleResponse(response) {
  var p = new Promise((resolve, reject) => {
    const data = response.data;
    if (response.status >= 400) {
      /*if (response.status === 401) {
          history(routesConstants.sign.OUT);
        }*/

      const error = response.statusText;
      reject(error);
    }

    resolve(data);
  });
  return p.then((data) => {
    return data;
  });
}

function parseOutputs(outputs) {
  if (outputs && outputs instanceof Array) {
    return outputs.map((output) => Output.parseOutputDtoFromObject(output));
  }
  return outputs;
}
