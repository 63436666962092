import React, { useState, useEffect, useCallback } from "react";
import { getDayOfYearFormatted } from "../../../../../_helpers/DateFormatHelper";

export const DayChoose = (props) => {

    const { mapDataGroupByDate, changeDay,personalizedClass } = props;
    const [showButtonNextDay, setshowButtonNextDay] = useState(false);
    const [showButtonYesterday, setshowButtonYesterday] = useState(true);

    const getMidnigth = useCallback(date => {
        date.setHours(0,0,0,0);
        return date;
    }, []);

    const getMinDate = useCallback(now => {
        const minDate = new Date(now.getTime());
        minDate.setDate(minDate.getDate() - 30);
        return getMidnigth(minDate);
    }, [getMidnigth]);

    const now = getMidnigth(new Date());
    const [selectedDay, setSelectedDay] = useState(now);
    const [minDate, setMinDate] = useState(getMinDate(now));
    const [maxDate, setMaxDate] = useState(now);
   
    const getDateKey = useCallback(date => {
        return getDayOfYearFormatted(date);
    }, []);

    //Obtiene primero el día actual, si se encuentra lo muestra y si nos muestra el primer día encontrado:
    useEffect(() => {
        if (mapDataGroupByDate !== undefined) {
            let keys = Array.from(mapDataGroupByDate.keys());
            if (keys.length > 0) {
                const tmpActualday = keys[keys.length - 1];
                let [year, month, day] = tmpActualday.split("-");
                const maxDate = new Date(year, month - 1, day, 0, 0, 0, 0);
                setSelectedDay(maxDate);
                setMaxDate(maxDate);
                setMinDate(getMinDate(maxDate));
            } else {
                setSelectedDay(getMidnigth(new Date()));
            }
        }
    }, [mapDataGroupByDate]);


    const NextDay = useCallback(() => {

        setshowButtonYesterday(true);
        let targetDate = new Date(selectedDay.getTime());
        targetDate.setDate(targetDate.getDate() + 1);
        targetDate = getMidnigth(targetDate);
        if(targetDate <= maxDate){
            setshowButtonNextDay(targetDate.getTime() !== now.getTime());
            changeDay(getDateKey(targetDate));
            setSelectedDay(targetDate);
        }
    }, [selectedDay, getMidnigth, maxDate, changeDay, getDateKey]);

    const YesterDay = useCallback(() => {
        // Nunca inferior al dia actual - 7 días.

        setshowButtonNextDay(true);
        let targetDate = new Date(selectedDay.getTime());
        targetDate.setDate(targetDate.getDate() - 1);
        targetDate = getMidnigth(targetDate);
        if(targetDate >= minDate){
            setshowButtonYesterday(targetDate.getTime() !== minDate.getTime());
            setSelectedDay(targetDate);
            changeDay(getDateKey(targetDate));
        }
    }, [selectedDay, getMidnigth, minDate, changeDay, getDateKey]);

    return (
        <><div className="selectorDatef">
            <div className="selectorday tsmDay">
                {/*  <div className="daybatery">Última carga al 100%:</div>*/}
                <div className="selectorsDaysBatery">

                    <div className={`fa ${!showButtonYesterday ? "fa-disabled" : ""} fa-fw fa-chevron-left icoSelectDayBatery`} onClick={(e) => {if(showButtonYesterday){YesterDay()}}}></div>
                    <div className="dayInDayBatery">  {getDateKey(selectedDay)}</div>
                    <div className={`fa ${!showButtonNextDay ? "fa-disabled" : ""} fa-fw fa-chevron-right icoSelectDayBatery`} onClick={(e) => {if(showButtonNextDay){NextDay()}}}></div>
                </div>
            </div>
        </div>
        </>
    );
};
