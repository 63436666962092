import React, { useEffect, useCallback, useState } from "react";
import Timer from "../../../Timer/Timer";

export const TimerRep = (props) => {
    const { time, timeWait,callback } = props;

    const [activateTimer, setactivateTimer] = useState(true);
    const [count, setcount] = useState(0);
    const executingTimer = useCallback((event) => {
        setcount(count + 1);
        callback();
    }, [activateTimer, count]);

    return (
        <Timer
            count={count}
            isActive={activateTimer}
            callback={executingTimer}
            callbackFirstExecutionDelayMiliseconds={0}
            callbackExecutionEveryMiliseconds={timeWait}
            timerIntervalMiliseconds={time}
        />
    )
}