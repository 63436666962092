import React from "react"
import { CartesianGrid, Legend, Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

export const TerminalDeviceSensorLineGraph = props => {

    const {xData, xLabel, yData, yReferenceLines} = props

    const lineColors = ["#75A3FF", "#424656", "#A6ABBD", "#FB7D94", "#BF4661"];
    const refLineColors = ["#52414c", "#596157", "#5b8c5a", "#e3655b", "#cfd186"];

    return <div className={"statisticsfatherTSMc"}>
      <ResponsiveContainer className={"statisticsfather"}>
        <LineChart
          width={500}
          height={300}
          data={xData}
          margin={{
            top: 20,
            right: 50,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" interval="preserveStartEnd" dx={20}>
            {xLabel}
          </XAxis>
          <YAxis
            domain={["dataMin", "dataMax"]}
            tickFormatter={(tick) => Math.round(tick * 100) / 100}
          />
          <Tooltip />
          <Legend verticalAlign="top" />
          {yReferenceLines instanceof Array && (
                yReferenceLines
                .map((value, index) => (
                  <ReferenceLine
                    key={index}
                    y={value}
                    stroke={refLineColors[index % refLineColors.length]}
                  ></ReferenceLine>
                ))
            )}
          {yData.map((measure, index) => {
            return (
              <Line
                key={index}
                type="monotone"
                name={measure}
                dataKey={measure}
                stroke={lineColors[lineColors.length % index]}
                dot={false}
              />
            );
          })}
        </LineChart>
      </ResponsiveContainer>
    </div>
}