import React, { useEffect, useCallback, useState } from "react";
import { projectsConstants } from "../../../../../_constants/projects.constants";
import { getFullDateTimeFormatted } from "../../../../../_helpers/DateFormatHelper";
import { Spinner } from "../../../Airframe/Spinner/Spinner";
import { Humidity } from "../../../Icon/Humidity";
import { Preassure } from "../../../Icon/Preassure";
import { Temperature } from "../../../Icon/Temperature";

import "./CardSensor.sass";
import { TimerRep } from "./TimerRep";
import { Average } from "../../../Icon/Average";
import { Live } from "../../../Icon/Live";

export const getUnitInfo = (sensorUnit) => {
  for (let unit in projectsConstants.global.sensors.units) {
    if (unit === sensorUnit) {
      return projectsConstants.global.sensors.units[unit];
    }
  }
};

export const getBadgeName = (unitInfo) => {
  return unitInfo?.badge || "sensor";
};

export const getBadgeClassName = (unitInfo) => {
  return unitInfo?.className || "badge-primary";
};

const dataMode = {
  NORMAL: "NORMAL",
  AVERAGE: "AVERAGE"
}

export const CardSensor = (props) => {
  const {
    id,
    sensorIndex,
    physicalCommunicationType,
    sensorUnit,
    sensorValue,
    alarm,
    dateTime,
    onClick,
    loading,
    labelPrefix,
    allowSwitchToAverage,
    dialyAverageSensorValue
  } = props;

  const timeToBlinkCard = 2000;
  const timeWait = 2000;

  const [unitInfo, setUnitInfo] = useState(undefined);
  const [alarmActivate, setalarmActivate] = useState(0);

  
  const [showDataMode, setShowDataMode] = useState(dataMode.NORMAL)

  const OnBlinkCardAlarm = useCallback(() => {
    setalarmActivate(alarm === sensorValue);
  }, [sensorValue, alarm]);
  //

  useEffect(() => {
    setUnitInfo(getUnitInfo(sensorUnit));
  }, [props, sensorUnit]);

  

  const getIcon = useCallback(() => {
    switch (unitInfo?.icon) {
      case "press":
        return <Preassure />;
      case "temp":
        return <Temperature className={"sensorUnitIcon"} />;
      case "humidity":
        return <Humidity />;
      default:
        return <></>;
    }
  }, [unitInfo]);

  const getSensorIndexLabel = useCallback(() => {
    return `${labelPrefix || 
      (physicalCommunicationType === projectsConstants.global.sensors.phys.cuds
        ? "Caud."
        : "S")
    }${sensorIndex + 1}`;
  }, [labelPrefix, physicalCommunicationType, sensorIndex]);

  const onDivClick = useCallback(() => {
    if (onClick) {
      onClick(sensorIndex);
    }
  }, [onClick, sensorIndex]);

  const getShowDataIcon = useCallback(() => {
    if(showDataMode === dataMode.NORMAL){
      return <Average onClick={() => { setShowDataMode(dataMode.AVERAGE) }}/>
    }
    
    return <Live onClick={() => { setShowDataMode(dataMode.NORMAL) }}/>
  }, [showDataMode])

  const getCommonMeasureComponent = useCallback((mainUnit, mainValue, secondaryUnit, secondaryValue) => {
    return <>
    <div style={{display: "flex", flexDirection: "column"}}>
        <p className= "sizesensorsText" style={{margin: "0px", lineHeight: "1"}}>{mainValue}</p>
        {!isNaN(secondaryValue) && <p className="subText" style={{margin: "0px", lineHeight: "1"}}>{secondaryValue}</p>}
      </div>
      <div style={{display: "flex", flexDirection: "column"}}>
        <p className= "sizesensorsText" style={{ fontSize: "0.8'rem", margin: "0px", lineHeight: 1}}>{`${mainUnit}`}</p>
        {!isNaN(secondaryValue) && <p className="subText" style={{margin: "0px", lineHeight: "1"}}>{secondaryUnit}</p>}
      </div>
    </>
  }, [])

  const getNormalComponent = useCallback(() => {
    return getCommonMeasureComponent(sensorUnit, sensorValue?.toFixed(2), `/día`, dialyAverageSensorValue?.toFixed(2))
  }, [dialyAverageSensorValue, getCommonMeasureComponent, sensorUnit, sensorValue])

  const getAverageComponent = useCallback(() => {
    return getCommonMeasureComponent(`${sensorUnit}/día`, dialyAverageSensorValue?.toFixed(2), sensorUnit, sensorValue?.toFixed(2))
  }, [dialyAverageSensorValue, getCommonMeasureComponent, sensorUnit, sensorValue])

  const getMeasureComponent = useCallback(() => {
    if(loading){
      return <Spinner />
    }

    if(showDataMode === dataMode.AVERAGE){
      return getAverageComponent()
    }

    return getNormalComponent()

  }, [getAverageComponent, getNormalComponent, loading, showDataMode])

  return (
    <>
      <TimerRep
        time={timeToBlinkCard}
        timeWait={timeWait}
        callback={OnBlinkCardAlarm}
      />

      <div
        key={id}
        className={
          alarmActivate
            ? "alarmActivate_SensorCard"
            : "alarmDesactivate_SensorCard"
        }
        onClick={onDivClick}
      >
        <div className="Title_Descripction SensorShow">
          <div className="card-body-sensor">
            <div className="titlecardsensor">
              <div className="titlecardsensorname">{getSensorIndexLabel()}</div>
              {allowSwitchToAverage ? getShowDataIcon() : (
                <div
                  className={`badge rounded-pill ml-auto ${getBadgeClassName(unitInfo)}`}
                >
                  {getBadgeName(unitInfo)}
                </div>
              )}
              
            </div>
            <div className="imagesensorandtext">
              <div className="sensordescription">
                {getMeasureComponent()}
                <div className="sensorImage">{getIcon()}</div>
              </div>
            </div>
            <div
              style={{
                fontSize: "0.5rem",
                display: "flex",
                justifyContent: "right",
              }}
            >
              {dateTime ? getFullDateTimeFormatted(dateTime) : " -- "}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
