import React, { useState, useEffect, useCallback } from "react";
import { getDayOfYearFormatted } from "../../../../../_helpers/DateFormatHelper";
import { CardSensor } from "./CardSensor";
import { sensorService } from "../../../../../_services/sensors.service";
import { projectsConstants } from "../../../../../_constants/projects.constants";

const states = {
  IDLE: "IDLE",
  LOADING: "LOADING",
  LOADED: "LOADED",

  ERROR: "ERROR",
};
const initState = {
  state: states.IDLE,
  register: undefined,
};

export const CudDigitalSensorCard = (props) => {
  const { terminalDevice, sensor, onSensorClick } = props;
  const [state, setState] = useState(initState);

  const onClickCardSensor = useCallback(() => {
    if(sensor && onSensorClick){
      onSensorClick(sensor.sensorIndex, sensor?.sensorId?.physicalCommunicationType)
    }
  }, [onSensorClick, sensor])

  useEffect(() => {
    setState(currentState => ({...currentState, state: states.IDLE}))
  }, [sensor?.lastValueAt])

  useEffect(() => {
    if (state.state === states.IDLE) {
      setState({ ...state, state: states.LOADING });
      const actualday = getDayOfYearFormatted(new Date(Date.now()));
      sensorService
        .getTerminalDeviceSensorRegistersByDay(
          terminalDevice?.terminal,
          terminalDevice?.id,
          sensor?.sensorId?.id,
          sensor?.sensorIndex,
          actualday,
          "DAY_ACCUMULATED"
        )
        .then(
          (registers) => {
            let register = undefined;
            if (registers instanceof Array && registers.length > 0) {
              register = registers[registers.length - 1];
            }
            setState({ ...state, state: states.LOADED, register });
          },
          (error) => {
            setState({ ...state, state: states.ERROR });
          }
        );
    }
  }, [sensor?.sensorId, sensor?.sensorIndex, state, terminalDevice?.id, terminalDevice?.terminal]);

  return (
    <CardSensor
      key={`${sensor?.sensorId}-${sensor?.sensorIndex}`}
      id={sensor?.sensorIndex + 1}
      sensorIndex={sensor?.sensorIndex}
      sensorValue={state.register?.accumulated?.accumulatedMeasures[0]?.value || 0}
      sensorUnit={state.register?.accumulated?.accumulatedMeasures[0]?.unit || "ud"}
      loading={sensor?.isRequesting || state.state === states.LOADING}
      alarm={0}
      dateTime={
        state.register?.receivedAt && new Date(state.register.receivedAt)
      }
      onClick={onClickCardSensor}
      labelPrefix={sensor?.sensorId?.physicalCommunicationType === projectsConstants.global.sensors.phys.digital ? "Pulsos " : "Caud. "}
    />
  );
};
