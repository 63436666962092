import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { terminalsConstants } from "../../../_constants/terminals.constants";
import { Authservice } from "../../../_services/Authservice";
import { Spinner } from "../Airframe/Spinner/Spinner";

import LogoTecatel from "./SquareView/LogoTecatel.png";
import { PlugOn } from "../Icon/PlugOn";
import { PlugTransition } from "../Icon/PlugTransition";
import { PlugOff } from "../Icon/PlugOff";
import { PopUpC } from "../Airframe/PopUp/PopUp";
import { FormUpload } from "./SquareView/FormUpload";
import { ReactComponent as Editsvg } from "./../../../images/editar.svg";

import { uploadTargetEntities } from "../File/FileUploader";
import { terminalService } from "../../../_services/terminal.service";
import { projectsConstants } from "../../../_constants/projects.constants";
import { Badge } from "../Badge/Badge";
import {isTerminalDeviceValidForReportSensorValues} from "../TerminalDevices/Fichas/SensorsConfiguration/ReportSensorValuesChecker"
import { FairSignal } from "../Icon/FairSignal";
import { BadSignal } from "../Icon/BadSignal";

export const getConnectionStatusIcon = (terminal) => {
  if(typeof terminal?.terminalType === "string" && projectsConstants.lora_outputs.id.toLowerCase() === terminal.terminalType.toLowerCase()){

    if(terminalsConstants.status.online.active === terminal?.statusMqtt){
      switch(terminal?.signalQuality){
        case projectsConstants.global.signal_quality.good:
          return <PlugOn />
        case projectsConstants.global.signal_quality.fair:
          return <FairSignal />
        case projectsConstants.global.signal_quality.bad:
          return <BadSignal />
        default:
          return <PlugTransition />
      }
    }

    return <PlugOff />
      

  }
  else{
    switch (terminal?.statusMqtt) {
      case terminalsConstants.status.online.transition:
        return <PlugTransition />;
      case terminalsConstants.status.online.active:
        return <PlugOn />;
      case terminalsConstants.status.online.deactived:
      default:
        return <PlugOff />;
    }
  }
}

export const TerminalWithSensorsCard = (props) => {
  const { terminal, onDescriptionChange, onSelect } = props;
  const { transition, active, deactived } = terminalsConstants.status.online;
  const terminalDeviceIndex = useRef(0);

  const states = useMemo(
    () => ({
      IDLE: "IDLE",
      LOADING_IMAGE: "LOADING_IMAGE",
      LOADED_IMAGE: "LOADED_IMAGE",

      TERMINAL_DEVICES_READ: "TERMINAL_DEVICES_READ",
      TERMINAL_DEVICES_READING: "TERMINAL_DEVICES_READING",
      TERMINAL_DEVICES_READ_DONE: "TERMINAL_DEVICES_READ_DONE",

      EDIT: "EDIT",

      DATA_READ_DONE: "DATA_READ_DONE",
    }),
    []
  );

  const initState = useMemo(
    () => ({
      state: states.IDLE,
      imageLoaded: false,
      imgSrc: undefined,
      imgRotation: 0,
      description: terminal?.description,
      terminalDevices: [],
    }),
    [states.IDLE, terminal?.description]
  );

  const [state, setState] = useState(initState);

  //#region on actions

  const onElementUpdate = useCallback((name, file, orientation) => {
    if (file) {
      let reader = new FileReader();
      reader.onloadend = () => {
        setState((current) => ({ ...current, imgSrc: reader.result }));
      };
      reader.readAsDataURL(file);
      //rota imagen en local
      setState((current) => ({ ...current, imgRotation: orientation }));
    }
    setState((current) => ({ ...current, description: name }));
  }, []);

  const ClosePopUp = useCallback(() => {
    if (terminal?.description !== state.description) {
      // Debemos actualizar
      onDescriptionChange(state.description);
    }
    setState({ ...state, state: states.LOADED_IMAGE });
  }, [terminal?.description, state, states.LOADED_IMAGE, onDescriptionChange]);

  const onEdit = useCallback(() => {
    setState((current) => ({ ...current, state: states.EDIT }));
  }, [states.EDIT]);

  const onTerminalSelected = useCallback(() => {
    onSelect(state.terminalDevices);
  }, [onSelect, state.terminalDevices]);
  //#endregion

  //#region useEffect

  useEffect(() => {
    switch (state.state) {
      case states.IDLE:
        if (terminal?.hasImage) {
          setState({ ...state, state: states.LOADING_IMAGE });
          Authservice.loadImage(
            `${process.env.REACT_APP_API_PATH}/terminals/${terminal?.id}/images/`
          )
            .then((img) => {
              setState({
                ...state,
                imageLoaded: true,
                state: states.LOADED_IMAGE,
                imgSrc: img,
              });
            })
            .catch((err) => {
              setState({
                ...state,
                imageLoaded: false,
                imgSrc: undefined,
                state: states.LOADED_IMAGE,
              });
            });
        } else {
          setState({
            ...state,
            imageLoaded: false,
            state: states.LOADED_IMAGE,
          });
        }
        break;
      case states.LOADING_IMAGE:
        break;
      case states.LOADED_IMAGE:
        setState({ ...state, state: states.TERMINAL_DEVICES_READ });
        break;
      case states.TERMINAL_DEVICES_READ:
        if (
          terminal?.terminalDevices?.length > 0 &&
          terminal.terminalDevices.length > terminalDeviceIndex.current
        ) {
          setState({ ...state, state: states.TERMINAL_DEVICES_READING });
          terminalService.getTerminalDevices(terminal?.id).then(
            (terminalDevices) => {
              setState({
                ...state,
                state: states.TERMINAL_DEVICES_READ_DONE,
                terminalDevices,
              });
            },
            (error) => {}
          );
        } else {
          setState({ ...state, state: states.TERMINAL_DEVICES_READ_DONE });
        }
        break;
      case states.TERMINAL_DEVICES_READING:
        break;
      case states.TERMINAL_DEVICES_READ_DONE:
        break;
      default:
        break;
    }
  }, [
    state,
    states.IDLE,
    states.LOADED_IMAGE,
    states.LOADING_IMAGE,
    states.SENSORS_READ,
    states.SENSORS_READING,
    states.SENSORS_READ_DONE,
    states.TERMINAL_DEVICES_READ,
    states.TERMINAL_DEVICES_READING,
    states.TERMINAL_DEVICES_READ_DONE,
    terminal,
  ]);

  //#endregion

  return (
    <div className="TerminalWithSensorCardContainer">
      <PopUpC
        activate={state.state === states.EDIT}
        deactivatepopup={ClosePopUp}
        content={
          <FormUpload
            nameProps={state.description}
            targetEntity={uploadTargetEntities.terminal}
            uploadEntityIds={[terminal?.id]}
            onUpdate={onElementUpdate}
          />
        }
      />

        <TerminalDeviceSensorsCardInfo
          terminalDevices={state.terminalDevices}
        ></TerminalDeviceSensorsCardInfo>

      <div onClick={onTerminalSelected}>
        {state.state === states.LOADING_IMAGE ? (
          <div className="SpinnerCard">
            <div className={"LoadspinnerImage"}>
              <Spinner size={"50px"} />
            </div>
          </div>
        ) : (
          <img
            src={state.imgSrc || LogoTecatel}
            alt="Logo"
            className={`TerminalWithSensorCardImage Content${
              terminal?.imageOrientation || ""
            }`}
          />
        )}

        <div className="TerminalWithSensorCardOnlineContainer">
          {getConnectionStatusIcon(terminal)}
        </div>

      </div>
      <>
        <div className="contenttitlecard">
          <div className="TitleTerminalName">{state?.description}</div>
          <Editsvg className="ICoCard" onClick={onEdit} />
        </div>
      </>
    </div>
  );
}; 

const TerminalDeviceSensorsCardInfo = (props) => {
  const { terminalDevices } = props;

  const getTerminalDeviceSensorsInfo = useCallback(() => {
    if (terminalDevices instanceof Array && terminalDevices.length > 0) {
      return terminalDevices
        .filter(isTerminalDeviceValidForReportSensorValues)
        .flatMap((terminalDevice) => terminalDevice.sensors)
        .filter(
          (terminalDeviceSensor) =>
            terminalDeviceSensor?.active &&
            terminalDeviceSensor?.sensorId?.name !==
              projectsConstants.global.sensors.name.fake_digital &&
            terminalDeviceSensor?.sensorId?.name !==
              projectsConstants.global.sensors.name.cuds
        )
        .map((terminalDeviceSensor) => {
          if (terminalDeviceSensor?.measurement) {
            return {
              lastValue: terminalDeviceSensor?.lastValue,
              ...terminalDeviceSensor.measurement,
            };
          }
          return {
            lastValue: terminalDeviceSensor?.lastValue,
            ...terminalDeviceSensor?.sensorId?.measurements[0],
          };
        })
        .map((superMeasurement) => {
          const equation = superMeasurement?.equation
            ? superMeasurement.equation.replace(
                /{sensorValue}/g,
                superMeasurement.lastValue || 0
              )
            : superMeasurement.lastValue || 0;
          return <Badge 
          value={`${eval(equation).toFixed(2)}${superMeasurement?.measurementUnit || "V"}`} 
          extraClassName="gray-borders"
          />
        })
        .slice(0, 4)
    }
  }, [terminalDevices]);

  return (
    <div className="TerminalWithSensorCardSensorsContainer">
      {getTerminalDeviceSensorsInfo()}
    </div>
  );
};
