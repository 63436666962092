import React, { useState, useEffect, useCallback } from "react";

import { Label } from "recharts";
import { sensorService } from "../../../../../_services/sensors.service";
import { useApp } from "../../../../../context/app-context";

import "./MultipleStatistics.sass";
import "./LiquidBarchart.sass";
import "./Statistics.sass";
import {
  getDayOfYearFormatted,
  getDateTimeFormatted,
} from "../../../../../_helpers/DateFormatHelper";
import { OutputService } from "../../../../../_services/outputcontroller.service";
import { TerminalDeviceSensorLineGraph } from "../../SensorsView/TerminalDeviceSensorLineGraph";
import { DayChoose } from "../../TerminalDevicesFather/TSM/DayChoose";
import { projectsConstants } from "../../../../../_constants/projects.constants";
import * as XLSX from "xlsx";

export const TerminalDeviceSensorStats = (props) => {
  const actualday = getDayOfYearFormatted(new Date(Date.now()));

  const { sensor, output } = props;

  const [daySelected, setDaySelected] = useState(actualday);
  const [data, setData] = useState([]);
  const [showAccumulated, setShowAccumulated] = useState(false);

  const { selectedTerminal } = useApp();

  useEffect(() => {
    if (selectedTerminal?.id) {
      let future;
      if (output?.terminalDevice && output?.output > 0) {
        future = OutputService.getTerminalDeviceOutputRegistersByDay(
          selectedTerminal.id,
          output.terminalDevice,
          output?.output,
          daySelected
        );
      } else if (
        sensor?.terminalDevice &&
        sensor?.sensorId?.id &&
        sensor?.sensorIndex !== undefined
      ) {
        future = sensorService.getTerminalDeviceSensorRegistersByDay(
          selectedTerminal.id,
          sensor.terminalDevice,
          sensor.sensorId.id,
          sensor.sensorIndex,
          daySelected
        );
      }

      if (future) {
        future.then(
          (registers) => {
            if (registers instanceof Array) {
              // Inicializamos un objeto vacío donde vamos a almacenar los arrays por unidad.
              const measuresByUnit = {};
              // Iteramos sobre cada entrada del array data.
              registers
                .sort(
                  (aRegister, bRegister) =>
                    new Date(Date.parse(aRegister.receivedAt)) -
                    new Date(Date.parse(bRegister.receivedAt))
                )
                .forEach((reg) => {
                  const date = new Date(Date.parse(reg.receivedAt));

                  if (reg.measures?.length > 0) {
                    reg.measures.forEach((measure) => {
                      // Si el array de la unidad aún no existe en el objeto measuresByUnit, lo creamos.
                      if (!measuresByUnit[measure.unit]) {
                        measuresByUnit[measure.unit] = {
                          units: [measure.unit],
                          measures: [],
                          accumulated: 0,
                          accumulatedMeasures: [],
                        };
                      }

                      measure.date = date;
                      measure.name = getDateTimeFormatted(date);
                      measure[measure.unit] = measure.value;
                      if (
                        measure.dailyAverage !== undefined &&
                        measure.dailyAverage !== null &&
                        !isNaN(measure.dailyAverage)
                      ) {
                        const averageUnit = `(${measure.unit})/24h`;
                        measure[averageUnit] = measure.dailyAverage;

                        if (
                          measuresByUnit[measure.unit].units.includes(
                            averageUnit
                          ) === false
                        ) {
                          measuresByUnit[measure.unit].units.push(averageUnit);
                        }
                      }

                      // Añadimos la medida al array correspondiente a su unidad.
                      measuresByUnit[measure.unit].measures.push(measure);

                      let accumulatedMeasure = { ...measure };
                      const accumulatedNew =
                        measuresByUnit[measure.unit].accumulated +
                        measure[measure.unit];
                      accumulatedMeasure[measure.unit] = accumulatedNew;
                      measuresByUnit[measure.unit].accumulated = accumulatedNew;
                      measuresByUnit[measure.unit].accumulatedMeasures.push(
                        accumulatedMeasure
                      );
                    });
                  } else {
                    measuresByUnit["ud"] = measuresByUnit["ud"] || {
                      units: ["ud"],
                      measures: [],
                      accumulated: 0,
                      accumulatedMeasures: [],
                    };
                    measuresByUnit["ud"].measures.push({
                      value: reg.rawValue,
                      unit: "ud",
                      date,
                    });
                  }
                });

              setData(measuresByUnit);
            }
          },
          (error) => {
            setData([]);
          }
        );
      }
    }
  }, [sensor, daySelected, selectedTerminal.id, output]);

  const getLabel = useCallback(() => {
    const title = output
      ? "Salida "
      : sensor?.sensorId?.physicalCommunicationType ===
        projectsConstants.global.sensors.phys.cuds
      ? "Caudalímetro "
      : "Sensor ";
    const descr = output?.description || sensor?.description || "";
    const id = output?.output || sensor?.sensorIndex + 1;

    return (
      <Label
        value={`${title} ${id} ${descr}`}
        offset={-5}
        position="insideBottom"
      />
    );
  }, [
    output,
    sensor?.description,
    sensor?.sensorId?.physicalCommunicationType,
    sensor?.sensorIndex,
  ]);

  const downloadTerminalDeviceSensorData = useCallback(
    (terminalDeviceSensorData) => {
      Object.keys(terminalDeviceSensorData).forEach((unit) => {
        const data = terminalDeviceSensorData[unit];
  
        // 1. Preparar los datos para el archivo Excel
        const worksheetData = [];
        
        // 2. Agregar los encabezados
        worksheetData.push(["Fecha", "Valor", "Media", "Unidad"]);
        
        // 3. Añadir los datos
        data.measures.forEach((item) => {
          worksheetData.push([
            `${item.date.toLocaleDateString()}-${item.date.toLocaleTimeString()}`,
            item.value,
            item.dailyAverage,
            unit
          ]);
        });
        
        // 4. Crear la hoja de cálculo (worksheet) y el libro de trabajo (workbook)
        const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "DatosSensor");
  
        // 5. Convertir el libro de trabajo a un archivo .xls
        const xlsData = XLSX.write(workbook, { bookType: "xls", type: "array" });
  
        // 6. Crear un blob con el contenido de Excel
        const blob = new Blob([xlsData], { type: "application/vnd.ms-excel" });
  
        // 7. Crear un enlace temporal para la descarga
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `${selectedTerminal?.description}_${sensor?.description ?? ""}(S${sensor?.sensorIndex + 1})_${unit}_${daySelected}.xls`;
  
        // 8. Simular el click para iniciar la descarga
        document.body.appendChild(link);
        link.click();
  
        // 9. Remover el enlace temporal
        document.body.removeChild(link);
      });
    },
    []
  );

  return (
    <>
      <div className="TitleMultipleStatics">
        <DayChoose changeDay={setDaySelected} />
        {(output ||
          sensor?.sensorId?.physicalCommunicationType ===
            projectsConstants.global.sensors.phys.cuds ||
          sensor?.sensorId?.physicalCommunicationType ===
            projectsConstants.global.sensors.phys.digital) && (
          <div
            className="Button Secondary"
            style={{ width: "30%" }}
            onClick={(e) => setShowAccumulated((current) => !current)}
          >
            {showAccumulated ? "Intervalo" : "Acumulado"}
          </div>
        )}
        <div
          className="Button Secondary"
          onClick={(e) => downloadTerminalDeviceSensorData(data)}
        >
          <i class="fa fa-download fa-1" aria-hidden="true"></i>
        </div>
      </div>
      {Object.keys(data).map((unit) => {
        return (
          <TerminalDeviceSensorLineGraph
            xData={
              showAccumulated
                ? data[unit].accumulatedMeasures
                : data[unit].measures
            }
            xLabel={getLabel()}
            yData={data[unit].units}
            yReferenceLines={
              sensor?.triggers instanceof Array &&
              sensor.triggers.filter((trigger) => !trigger?.deletedByUser)
            }
          />
        );
      })}
    </>
  );
};
