import React, { useCallback, useRef, useState } from "react";
import { projectsConstants } from "../../../../_constants/projects.constants";
import Timer from "../../Timer/Timer";
import { CardSensor } from "./CardSensor/CardSensor";
import { NotCardSensor } from "./NotCardSensor/NotCardSensor";
import uuid from "uuid/v4";
import { MESSAGE_TYPE_ACTION } from "../../../../_constants/messageType.constants";
import { usePublisher } from "../../../../context/publish-context";
import { useMqttState } from "mqtt-react-hooks";
import { CudDigitalSensorCard } from "./CardSensor/CudDigitalSensorCard";
import { isTerminalDeviceValidForReportSensorValues } from "../Fichas/SensorsConfiguration/ReportSensorValuesChecker";

export const TerminalDeviceSensorsView = (props) => {
  const { terminalDevice } = props
  
  const { connectionStatus: status } = useMqttState()
  const { publish } = usePublisher();
  const sensorsQueryInterval = useRef(30);

  const states = {
    IDLE: "IDLE",
    REQUESTING: "REQUESTING"
  }
  const initState = {
    state: states.IDLE
  }
  const [state, setState] = useState(initState)

  const publishGetSensorValue = useCallback((sensor, physical_communication = projectsConstants.global.sensors.phys.analog) => {
    sensor.lastRequestDateTime = new Date();
    let request = {
      type: MESSAGE_TYPE_ACTION,
      id: uuid(),
      data: {
        target_id: terminalDevice.id,
        id: sensor.sensorIndex + 1,
        physical_communication
      },
    };

    publish(projectsConstants.master_outputs.actions.getsensorvalue, request);
  }, [publish, terminalDevice.id]);

  const requestSensorValue = useCallback((sensorIndex, physType) => {
    if (terminalDevice?.sensors instanceof Array) {
      terminalDevice.sensors
        .filter(
          (sensor) =>
            sensor?.sensorIndex === sensorIndex &&
            sensor?.sensorId?.physicalCommunicationType === physType
        )
        .forEach(sensor => {
          sensor.isRequesting = true
          publishGetSensorValue(sensor, physType);
        });
        setState(prev => {return {...prev, state: states.REQUESTING}})
    }
  }, [publishGetSensorValue, states.REQUESTING, terminalDevice.sensors]);

  const onSensorClick = useCallback(
    (sensorIndex, physicalCommunicationType = projectsConstants.global.sensors.phys.analog) => {
      requestSensorValue(
        sensorIndex,
        physicalCommunicationType
      );
    },
    [requestSensorValue]
  );

  const wrapSensorAndMeasruement = useCallback((sensor, measurement) => {
    const isValidForReportSensors = isTerminalDeviceValidForReportSensorValues(terminalDevice)
    return {
      sensor,
      ...measurement,
      equation: (measurement?.equation && !isValidForReportSensors)
        ? measurement.equation.replace(
            /{sensorValue}/g,
            sensor.lastValue || 0
          )
        : sensor.lastValue || 0,
    };
  }, [terminalDevice])

  const getAnalogSensorComponents = useCallback(() => {
    if (terminalDevice?.sensors instanceof Array) {
      let cardSensors = terminalDevice.sensors
        .filter(
          (sensor) =>
            sensor?.active &&
            sensor?.sensorId?.physicalCommunicationType ===
              projectsConstants.global.sensors.phys.analog
        )
        .flatMap(sensor => {
          let measurements = []
          if(sensor.measurement){
            measurements.push(wrapSensorAndMeasruement(sensor, sensor.measurement))
          }
          if (sensor.sensorId.measurements instanceof Array && sensor.sensorId.measurements.length > 0) {
            measurements = measurements.concat(sensor.sensorId.measurements.map((measurement) => {
              return wrapSensorAndMeasruement(sensor, measurement)
            }))
          }
          return measurements.length > 0 ? measurements : [
            {
              sensor,
              calibrationRawValue: 1,
              calibrationUnitValue: 1,
              equation: `${sensor.lastValue || 0}`,
              measurementUnit: "V",
              sensorUnit: "V",
              offset: 0
            },
          ];
        })
        .map((joinedSensor, index) => {
          let measureValue = eval(joinedSensor.equation);
          return (
            <CardSensor
              key={index}
              physicalCommunicationType={
                projectsConstants.global.sensors.phys.analog
              }
              id={joinedSensor.sensor.id}
              sensorIndex={joinedSensor.sensor.sensorIndex}
              sensorValue={measureValue < 0 ? (joinedSensor.offset < 0 ? measureValue : 0) : measureValue}
              sensorUnit={joinedSensor.measurementUnit}
              loading={joinedSensor?.sensor?.isRequesting && state.state === states.REQUESTING}
              alarm={0}
              dateTime={
                joinedSensor.sensor.lastValueAt &&
                new Date(joinedSensor.sensor.lastValueAt)
              }
              onClick={onSensorClick}
            />
          );
        });

      return cardSensors.length >= 0 ? (
        cardSensors
      ) : (
        <NotCardSensor message={"No hay sensores analógicos activos."} />
      );
    }
    return <>No hay sensores analógicos configurados.</>;
  }, [onSensorClick, state.state, states.REQUESTING, terminalDevice.sensors, wrapSensorAndMeasruement]);

  const getDigitalSensorComponents = useCallback(() => {
    if (terminalDevice?.sensors instanceof Array) {
      let cardSensors = terminalDevice.sensors
        .filter(
          (sensor) =>
            sensor?.active &&
            (sensor?.sensorId?.physicalCommunicationType ===
              projectsConstants.global.sensors.phys.cuds 
              || sensor?.sensorId?.physicalCommunicationType ===
              projectsConstants.global.sensors.phys.digital)
        )
        .map((sensor, index) => <CudDigitalSensorCard key={index} terminalDevice={terminalDevice} sensor={sensor} onSensorClick={onSensorClick}/>)
      return cardSensors.length >= 0 ? (
        cardSensors
      ) : (
        <NotCardSensor message={"No hay caudalímetros activos."} />
      );
    }
    return <>No hay caudalímetros configurados.</>;
  }, [onSensorClick, terminalDevice]);

  //#region request sensor values

  const isBiggertThanInterval = useCallback((end, start) => {
    const dif = Math.abs(start.getTime() - end.getTime());
    const secondsDiff = dif / 1000;
    return secondsDiff >= sensorsQueryInterval.current - 5;
  }, []);

  const checkSensorsToRequest = useCallback(() => {
    if (terminalDevice?.sensors instanceof Array) {
      const nowDateTime = new Date();

      terminalDevice.sensors
        .filter(
          (sensor) =>
            sensor?.active &&
            sensor?.sensorId?.physicalCommunicationType ===
              projectsConstants.global.sensors.phys.analog &&
            isBiggertThanInterval(
              nowDateTime,
              sensor?.lastRequestDateTime || new Date(0)
            )
        )
        .forEach(publishGetSensorValue)
    }
  }, [isBiggertThanInterval, publishGetSensorValue, terminalDevice.sensors]);

  //#endregion

  return (
    <>
      <div className="sensorsCardFather">{getAnalogSensorComponents()}</div>
      <div className="sensorsCardFather">{getDigitalSensorComponents()}</div>
      {!isTerminalDeviceValidForReportSensorValues(terminalDevice) &&
        status === "Connected" && (
          <>
            <Timer
              isActive={true}
              callback={checkSensorsToRequest}
              callbackFirstExecutionDelayMiliseconds={0}
              callbackExecutionEveryMiliseconds={sensorsQueryInterval * 1000}
              timerIntervalMiliseconds={5000}
            />
          </>
        )}
    </>
  );
};
